/*
| ===================================================
| This File is for the dialog window
| ===================================================
*/
<template>
  <v-dialog
      v-model="value"
      width="500"
      :persistent="persistent"
      @click:outside="close"
      overlay-color="#425D6C"
      overlay-opacity=".8"
      style="border-radius: 16px !important;"
  >
    <slot></slot>
  </v-dialog>
</template>

<script>
export default {
  name: "AppDialog",
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false
    },
    persistent: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  methods: {
    close() {
      if (this.persistent) return;
      this.$emit('close');
    }
  },
}
</script>